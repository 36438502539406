import * as React from "react"
import { Link } from "gatsby"
import SEO from "../components/SEO"
import FooterComponent from "../components/FooterComponent"

const NotFoundPage = () => {
  return (
    <>
      <main className="bg-grey-100 container px-6 mx-auto text-center my-10">
        <h1 className="text-2xl text-black md:text-4xl font-bold mb-6">
          Puslapis nerastas
        </h1>
        <p className="text-lg text-black">
          Atsiprašome, tačiau negalime rasti šio puslapio 😔
          <br />
          {/* {process.env.NODE_ENV === "development" ? (
          <>
            <br />
            Try creating a page in <code>src/pages/</code>.
            <br />
          </>
        ) : null}
        <br /> */}
        </p>
        <Link to="/" className="text-lg text-yellow-700 hover:text-blue-500 pt-2 block">
          Grįžti atgal.
        </Link>
      </main>
      <FooterComponent />
    </>
  );
}

export default NotFoundPage

export const Head = () => <SEO />;
